import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Create() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const myFormik = useFormik(
    {
      initialValues: {
        uuid: "",
        name: "",
        category: "",
        has_v2: 0,
        premium: 0,
        thumbnail_url: "",
        thumbnail: "",
      },
      // Validating Forms while entering the data
      validate: (values) => {
        let errors = {}           //Validating the form once the error returns empty else onsubmit won't work

        if (!values.uuid) {
          errors.uuid = "Please enter uuid";
        }

        return errors;
      },
      //one can be able to submit once the validates returns empty value (validation successful) else can't be submitted
      onSubmit: async (values) => {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("uuid", values.uuid);
          formData.append("name", values.name);
          formData.append("category", values.category);
          formData.append("has_v2", values.has_v2);
          formData.append("premium", values.premium);
          if (values.thumbnail) {
            formData.append("thumbnail", values.thumbnail);
          }

          await axios.post(`https://picfixer.app/api/fairy-ai/new`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          navigate("/portal/fairy-ai-list");
        } catch (error) {
          console.log(error);
          alert("Validation failed");
          setLoading(false);
        }

      }

    });
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      myFormik.setFieldValue('thumbnail', file);

      // Tạo URL cho file ảnh để hiển thị xem trước
      const reader = new FileReader();
      reader.onloadend = () => {
        myFormik.setFieldValue('thumbnail_url', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className='container'>

      <form onSubmit={myFormik.handleSubmit}>
        <div className='row'>
          <div className="col-lg-6">
            <label className='font-weight-bold'>UUID</label>
            <input name='uuid' value={myFormik.values.uuid} onChange={myFormik.handleChange} type={"text"}
                   className={`form-control ${myFormik.errors.uuid ? "is-invalid" : ""} `} />
            <span style={{ color: "red" }}>{myFormik.errors.uuid}</span>
          </div>

          <div className="col-lg-6">
            <label className='font-weight-bold'>Name</label>
            <input name='name' value={myFormik.values.name} onChange={myFormik.handleChange} type={"mail"}
                   className={`form-control ${myFormik.errors.name ? "is-invalid" : ""} `} />
            <span style={{ color: "red" }}>{myFormik.errors.name}</span>
          </div>

          <div className="col-lg-6">
            <label className='font-weight-bold'>Category</label>
            <select name='category' value={myFormik.values.category} onChange={myFormik.handleChange}
                    className={`form-control ${myFormik.errors.category ? "is-invalid" : ""} `} >
              <option value="">----Select----</option>
              <option value="cartoon">Cartoon</option>
              <option value="anime">Anime</option>
              <option value="restyle">Restyle</option>
            </select>
            <span style={{ color: "red" }}>{myFormik.errors.category}</span>
          </div>


          <div className='col-lg-4'>
            <label className='font-weight-bold'>Premium</label>
            <div className='col-lg-6 pl-4'>
              <input
                  type="radio"
                  name="premium"
                  value={1}
                  checked={myFormik.values.premium == 1}
                  id='premium-true'
                  onChange={myFormik.handleChange}
                  className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
              />
              <label className="form-check-label" htmlFor="premium-true">True</label>
            </div>
            <div className='col-lg-6 pl-4'>
              <input
                  type="radio"
                  name="premium"
                  value={0}
                  checked={myFormik.values.premium == 0}
                  id='premium-false'
                  onChange={myFormik.handleChange}
                  className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
              />
              <label className="form-check-label" htmlFor="premium-false">False</label>
            </div>
            <span style={{ color: "red" }}>{myFormik.errors.premium}</span>
          </div>
          Premium: {myFormik.values.premium}

          <div className='col-lg-4'>
            <label className='font-weight-bold'>Has V2</label>
            <div className='col-lg-6 pl-4'>
              <input
                  type="radio"
                  value={1}
                  name='has_v2'
                  id='has_v2-true'
                  checked={myFormik.values.has_v2 == 1}
                  onChange={myFormik.handleChange}
                  className={`form-check-input ${myFormik.errors.has_v2 ? "is-invalid" : ""}`}
              />
              <label className="form-check-label" htmlFor="has_v2-true">True</label>
            </div>
            <div className='col-lg-6 pl-4'>
              <input
                  type="radio"
                  name="has_v2"
                  value={0}
                  id='has_v2-false'
                  checked={myFormik.values.has_v2 == 0}
                  onChange={myFormik.handleChange}
                  className={`form-check-input ${myFormik.errors.has_v2 ? "is-invalid" : ""}`}
              />
              <label className="form-check-label" htmlFor="has_v2-false">False</label>
            </div>
            <span style={{ color: "red" }}>{myFormik.errors.has_v2}</span>
          </div>
          Has v2: {myFormik.values.has_v2}

          <div className='col-lg-12'>
            <label className='font-weight-bold'>Image</label>
            <input type="file" accept="image/*" className='form-control-file' onChange={handleImageUpload} />
          </div>

          <div className='col-lg-4 mt-3'>
            <input disabled={isLoading} type="submit" value={isLoading ? "Submitting..." : "Create"} className=' btn btn-primary' />
          </div>
        </div>
      </form>
      {/* {JSON.stringify(myFormik.values)} */}
    </div>
  );
}

export default Create