import axios from 'axios';
import { useFormik } from 'formik';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';

function FaceSwapCreate() {
  const [isLoading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryList().then(() => {});
  }, [])

  let getCategoryList = async () => {
    try {
      const res = await axios.get("https://picfixer.app/api/categories/list", {
        params: {
          type: 'face_swap'
        }
      });
      setCategories(res.data?.data?.items ?? []);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const myFormik = useFormik(
      {
        initialValues: {
          name: "",
          category_id: "",
          premium: 0,
          video_urls: [],
          videos: [],
        },
        // Validating Forms while entering the data
        validate: (values) => {
          return {};
        },
        //one can be able to submit once the validates returns empty value (validation successful) else can't be submitted
        onSubmit: async (values) => {
          try {
            setLoading(true);
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("category_id", values.category_id);
            formData.append("premium", values.premium);

            values.videos.forEach((video, index) => {
              formData.append(`videos[${index}]`, video);
            });


            await axios.post(`https://picfixer.app/api/face-swap/new`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            navigate("/portal/face-swap-list");
          } catch (error) {
            console.log(error);
            alert("Validation failed");
            setLoading(false);
          }

        }

      });

  const handleVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      myFormik.setFieldValue('videos', files);

      // Create preview URLs for videos
      const videoUrls = files.map(file => URL.createObjectURL(file));
      myFormik.setFieldValue('video_urls', videoUrls);
    }
  };

  return (
      <div className='container'>

        <form onSubmit={myFormik.handleSubmit}>
          <div className='row'>

            <div className="col-lg-6">
              <label className='font-weight-bold'>Name</label>
              <input name='name' value={myFormik.values.name} onChange={myFormik.handleChange} type={"mail"}
                     className={`form-control ${myFormik.errors.name ? "is-invalid" : ""} `} />
              <span style={{ color: "red" }}>{myFormik.errors.name}</span>
            </div>

            <div className='col-lg-12'>
              <label className='font-weight-bold'>Category</label>
              <select
                  name='category_id'
                  value={myFormik.values.category_id}
                  onChange={myFormik.handleChange}
                  className={`form-control ${myFormik.errors.category_id ? "is-invalid" : ""}`}
              >
                <option value="">----Select----</option>
                {categories.map((category, index) => {
                  let categoryName = category?.name ?? '';
                  let typeLabel = categoryName.replace('_', ' ');
                  let capitalizedLabel = typeLabel
                      .split(' ')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ');
                  return (
                      <option key={index} value={category.id}>{capitalizedLabel}</option>
                  )
                })}
              </select>
              <span style={{ color: "red" }}>{myFormik.errors.category_id}</span>
            </div>

            <div className='col-lg-4'>
              <label className='font-weight-bold'>Premium</label>
              <div className='col-lg-6 pl-4'>
                <input
                    type="radio"
                    name="premium"
                    value={1}
                    checked={myFormik.values.premium == 1}
                    id='premium-true'
                    onChange={myFormik.handleChange}
                    className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
                />
                <label className="form-check-label" htmlFor="premium-true">True</label>
              </div>
              <div className='col-lg-6 pl-4'>
                <input
                    type="radio"
                    name="premium"
                    value={0}
                    checked={myFormik.values.premium == 0}
                    id='premium-false'
                    onChange={myFormik.handleChange}
                    className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
                />
                <label className="form-check-label" htmlFor="premium-false">False</label>
              </div>
              <span style={{ color: "red" }}>{myFormik.errors.premium}</span>
            </div>

            <div className='col-lg-12'>
              <label className='font-weight-bold'>Video (Multiples)</label>
              <input
                  type="file"
                  accept="video/*"
                  multiple
                  className='form-control-file'
                  onChange={handleVideoUpload}
              />
            </div>

            <div className='col-lg-12 mt-3'>
              <div className="row">
                {myFormik.values.video_urls.map((videoUrl, index) => (
                    <div key={index} className="col-lg-4">
                      <video width="100%" controls src={videoUrl} />
                    </div>
                ))}
              </div>
            </div>


            <div className='col-lg-4 mt-3'>
              <input disabled={isLoading} type="submit" value={isLoading ? "Submitting..." : "Create"} className=' btn btn-primary' />
            </div>
          </div>
        </form>
        {/* {JSON.stringify(myFormik.values)} */}
      </div>
  );
}

export default FaceSwapCreate