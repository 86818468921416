import axios from 'axios';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

function CategoryEdit() {
    const params = useParams();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getCategoryData().then(r => {})
    }, [])

    let getCategoryData = async () => {
        try {
            const res = await axios.get(`https://picfixer.app/api/categories/detail/${params.id}`);
            const category = res.data?.data ?? []
            if (!category) {
                navigate("/portal/category-list")
            } else {
                await myFormik.setValues(category);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            navigate("/portal/category-list")
        }
    }
    
    const myFormik = useFormik({
        initialValues: {
            name: "",
            type: "",
            icon_url: "",
            icon: null
        },
        // Validating Forms while entering the data
        validate: (values) => {
            return {};
        },

        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append("name", values.name);
                formData.append("type", values.type);


                if (values.icon) {
                    formData.append("icon", values.icon);
                }
                await axios.post(`https://picfixer.app/api/categories/update/${params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setLoading(false);
                navigate("/portal/category-list")
            } catch (error) {
                setLoading(false);
            }
        }
    })

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            myFormik.setFieldValue('icon', file);

            // Tạo URL cho file ảnh để hiển thị xem trước
            const reader = new FileReader();
            reader.onloadend = () => {
                myFormik.setFieldValue('icon_url', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };



    const types = [
        'face_dance', 'face_swap'
    ]

    return (
        <>
            <h3>Category - Id : {params.id} </h3>
            <div className='container'>
                <form onSubmit={myFormik.handleSubmit}>
                    <div className='row'>

                        <div className="col-lg-6">
                            <label className='font-weight-bold'>Name</label>
                            <input name='name' value={myFormik.values.name} onChange={myFormik.handleChange} type={"mail"}
                                className={`form-control ${myFormik.errors.name ? "is-invalid" : ""} `} />
                            <span style={{ color: "red" }}>{myFormik.errors.name}</span>
                        </div>

                        <div className='col-lg-12'>
                            <label className='font-weight-bold'>Type</label>
                            <select
                                name="type"
                                value={myFormik.values.type}
                                onChange={myFormik.handleChange}
                                className={`form-control ${myFormik.errors.type ? "is-invalid" : ""}`}
                            >
                                <option value="">----Select----</option>
                                {types.map((type, index) => {
                                    let typeLabel = type.replace('_', ' ');
                                    let capitalizedLabel = typeLabel
                                        .split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ');
                                    return (
                                        <option key={index} value={type}>{capitalizedLabel}</option>
                                    )
                                })}
                            </select>
                            <span style={{ color: "red" }}>{myFormik.errors.type}</span>
                        </div>


                        <div className='col-lg-12'>
                            <label className='font-weight-bold'>Icon</label>
                            { myFormik.values.icon_url ? (
                                <>
                                    <img src={myFormik.values.icon_url} alt="icon" className='thumb-img' />
                                </>
                            ) : (
                                <>
                                    <p>Icon empty</p>
                                </>
                            )}
                            <input type="file" name="icon" accept="image/*" className='form-control-file' onChange={handleImageUpload}/>

                        </div>


                        <div className='col-lg-4 mt-3'>
                            <input disabled={isLoading} type="submit" value={isLoading ? "Updating..." : "Update"} className=' btn btn-primary' />
                        </div>
                    </div>
                </form>
                {/* {JSON.stringify(myFormik.values)} */}
            </div>
        </>


    )
}

export default CategoryEdit