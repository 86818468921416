import { faUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

function FaceDanceList() {
  const [faceDanceList, setFaceDanceList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    getFaceDanceList().then(r => {});
  }, []);

  let getFaceDanceList = async () => {
    try {
      const res = await axios.get("https://picfixer.app/api/face-dance/list");
      setFaceDanceList(res.data?.data?.items ?? []);
      console.log(faceDanceList);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  let handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure do you want to delete the data?");
      if (confirmDelete) {
        await axios.post(`https://picfixer.app/api/face-dance/delete/${id}`);
        await getFaceDanceList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const filteredList = faceDanceList.filter(faceDance =>
      selectedCategory === "" || faceDance.category?.name === selectedCategory
  );

  const uniqueCategories = Array.from(new Set(faceDanceList.map(faceDance => faceDance.category?.name)));

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredList.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Fairy List</h1>
          <Link to="/portal/create-face-dance" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <FontAwesomeIcon icon={faUser} className="creating-face-dance mr-2" />
            Create Face Dance
          </Link>
        </div>
        {/* <!-- DataTables --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">DataTables</h6>
          </div>
          <div className="card-body">
            <div className="row pl-3">
              <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Filter by Name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
              <div className="mb-3 pl-3">
                <select
                    className="form-control"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">All Categories</option>
                  {uniqueCategories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                  ))}
                </select>
              </div>
            </div>

            {
              isLoading ? <img src='https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif' alt="Loading" />
                  : <div className="table-responsive">
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                      <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>UUID</th>
                        <th>Premium</th>
                        <th>Thumbnail Url</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tfoot>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>UUID</th>
                        <th>Premium</th>
                        <th>Thumbnail Url</th>
                        <th>Action</th>
                      </tr>
                      </tfoot>
                      <tbody>
                      {currentItems.map((faceDance) => {
                        const premium = faceDance?.premium;
                        const categoryName = faceDance.category?.name
                            .split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ');
                        return (
                            <tr key={faceDance.id}>
                              <td>{faceDance.id}</td>
                              <td>{faceDance.name}</td>
                              <td>{categoryName}</td>
                              <td>{faceDance?.uuid}</td>
                              <td>
                                <button className={premium === 1 ? 'btn btn-primary' : 'btn btn-danger'}>
                                  {premium === 1 ? 'True' : 'False'}
                                </button>
                              </td>
                              <td>{faceDance.thumbnail_url ? <img src={faceDance.thumbnail_url} alt="Thumbnail" className='face-dance-img' /> : ''}</td>
                              <th>
                                <Link to={`/portal/face-dance-edit/${faceDance.id}`} className='btn btn-info btn-sm mr-1'>Edit</Link>
                                <button onClick={() => handleDelete(faceDance.id)} className='btn btn-danger btn-sm mr-1'>Delete</button>
                              </th>
                            </tr>
                        )
                      })}
                      </tbody>
                    </table>
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
            }
          </div>
        </div>
      </>
  )
}

export default FaceDanceList
