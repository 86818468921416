import axios from 'axios';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom';

function CategoryCreate() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

  }, [])

  const myFormik = useFormik(
    {
      initialValues: {
        name: "",
        type: "",
        icon_url: "",
        icon: "",
      },
      // Validating Forms while entering the data
      validate: (values) => {
                   //Validating the form once the error returns empty else onsubmit won't work
        return {};
      },
      //one can be able to submit once the validates returns empty value (validation successful) else can't be submitted
      onSubmit: async (values) => {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("type", values.type);


          if (values.icon) {
            formData.append("icon", values.icon);
          }

          await axios.post(`https://picfixer.app/api/categories/new`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          navigate("/portal/category-list");
        } catch (error) {
          console.log(error);
          alert("Validation failed");
          setLoading(false);
        }

      }

    });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      myFormik.setFieldValue('icon', file);

      // Tạo URL cho file ảnh để hiển thị xem trước
      const reader = new FileReader();
      reader.onloadend = () => {
        myFormik.setFieldValue('icon_url', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const types = [
    'face_dance', 'face_swap'
  ]


  return (
    <div className='container'>

      <form onSubmit={myFormik.handleSubmit}>
        <div className='row'>

          <div className="col-lg-6">
            <label className='font-weight-bold'>Name</label>
            <input name="name" value={myFormik.values.name} onChange={myFormik.handleChange} type={"name"}
                   className={`form-control ${myFormik.errors.name ? "is-invalid" : ""} `} />
            <span style={{ color: "red" }}>{myFormik.errors.name}</span>
          </div>

          <div className='col-lg-12'>
            <label className='font-weight-bold'>Type</label>
            <select
                name="type"
                value={myFormik.values.type}
                onChange={myFormik.handleChange}
                className={`form-control ${myFormik.errors.type ? "is-invalid" : ""}`}
            >
              <option value="">----Select----</option>
              {types.map((type, index) => {
                let typeLabel = type.replace('_', ' ');
                let capitalizedLabel = typeLabel
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                return (
                  <option key={index} value={type}>{capitalizedLabel}</option>
                )
              })}
            </select>
            <span style={{ color: "red" }}>{myFormik.errors.type}</span>
          </div>

          <div className='col-lg-12'>
            <label className='font-weight-bold'>Icon</label>
            <input type="file" accept="image/*" className='form-control-file' onChange={handleImageUpload} />
          </div>


          <div className='col-lg-4 mt-3'>
            <input disabled={isLoading} type="submit" value={isLoading ? "Submitting..." : "Create"} className=' btn btn-primary' />
          </div>
        </div>
      </form>
      {/* {JSON.stringify(myFormik.values)} */}
    </div>
  );
}

export default CategoryCreate