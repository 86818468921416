import { faUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

function CategoryList() {
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState("");
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    getCategoryList().then(r => {});
  }, []);

  let getCategoryList = async () => {
    try {
      const res = await axios.get("https://picfixer.app/api/categories/list");
      setCategoryList(res.data?.data?.items ?? []);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  let handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure do you want to delete the data?");
      if (confirmDelete) {
        await axios.post(`https://picfixer.app/api/categories/delete/${id}`);
        await getCategoryList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const uniqueTypes = ['face_dance', 'face_swap'];
  const filteredList = categoryList.filter(category =>
      category.name.toLowerCase().includes((filterText || "").toLowerCase()) &&
      (selectedType === "" || category.type === selectedType)
  );


  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredList.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
      <>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Category List</h1>
          <Link to="/portal/create-category" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <FontAwesomeIcon icon={faUser} className="creating-category mr-2" />
            Create Category
          </Link>
        </div>
        {/* <!-- DataTables --> */}
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">DataTables</h6>
          </div>
          <div className="card-body">
            <div className="row pl-3">
              <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Filter by Name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
              </div>
              <div className="mb-3 pl-3">
                <select
                    className="form-control"
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value="">All Types</option>
                  {uniqueTypes.map((category, index) => {
                    const type = category.replace('_', ' ')
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');
                    return (
                      <option key={index} value={category}>
                        {type}
                      </option>
                    )}
                  )}
                </select>
              </div>
            </div>

            {
              isLoading ? <img src='https://media.giphy.com/media/ZO9b1ntYVJmjZlsWlm/giphy.gif' alt="Loading" />
                  : <div className="table-responsive">
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                      <thead>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>UUID</th>
                        <th>Icon url</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tfoot>
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>UUID</th>
                        <th>Icon url</th>
                        <th>Action</th>
                      </tr>
                      </tfoot>
                      <tbody>
                      {currentItems.map((category) => {
                        let typeLabel = category?.type.replace('_', ' ');
                        let capitalizedLabel = typeLabel
                            .split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' ');
                        return (
                            <tr key={category.id}>
                              <td>{category.id}</td>
                              <td>{category.name}</td>
                              <td>{capitalizedLabel}</td>
                              <td>{category?.uuid}</td>
                              <td>{category.icon_url ? <img src={category.icon_url} alt="Thumbnail" className='thumb-img' /> : ''}</td>
                              <th>
                                <Link to={`/portal/category-edit/${category.id}`} className='btn btn-info btn-sm mr-1'>Edit</Link>
                                <button onClick={() => handleDelete(category.id)} className='btn btn-danger btn-sm mr-1'>Delete</button>
                              </th>
                            </tr>
                        )
                      })}
                      </tbody>
                    </table>
                    <nav>
                      <ul className="pagination">
                        {[...Array(totalPages).keys()].map(number => (
                            <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                              <a onClick={() => paginate(number + 1)} className="page-link">
                                {number + 1}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
            }
          </div>
        </div>
      </>
  )
}

export default CategoryList
