import './App.css';
import "./sb-admin-2.min.css";
import Dashboard from './Dashboard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Userlist from './Userlist';
import FairyAiList from "./fairy-ai/List";
import Portal from './Portal';
import UserCreate from './UserCreate';
import UserView from './UserView';
import UserEdit from './UserEdit';
import Create from "./fairy-ai/Create";
import Edit from "./fairy-ai/Edit";
import FaceDanceList from "./face-dance/List";
import FaceDanceEdit from "./face-dance/Edit";
import FaceDanceCreate from "./face-dance/Create";
import FaceSwapList from "./face-swap/List";
import FaceSwapEdit from "./face-swap/Edit";
import FaceSwapCreate from "./face-swap/Create";
import CategoryList from "./category/List";
import CategoryEdit from "./category/Edit";
import CategoryCreate from "./category/Create";
import RequireAuth from "./components/RequireAuth";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
          <Route path="/portal" element={<RequireAuth />}>
              <Route path="" element={<Portal />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="user-list" element={<Userlist />} />
                  <Route path="create-user" element={<UserCreate />} />
                  <Route path="user-view/:id" element={<UserView />} />
                  <Route path="user-edit/:id" element={<UserEdit />} />

                  <Route path="fairy-ai-list" element={<FairyAiList />} />
                  <Route path="create-fairy-ai" element={<Create />} />
                  <Route path="fairy-ai-edit/:id" element={<Edit />} />

                  <Route path="face-dance-list" element={<FaceDanceList />} />
                  <Route path="create-face-dance" element={<FaceDanceCreate />} />
                  <Route path="face-dance-edit/:id" element={<FaceDanceEdit />} />

                  <Route path="face-swap-list" element={<FaceSwapList />} />
                  <Route path="create-face-swap" element={<FaceSwapCreate />} />
                  <Route path="face-swap-edit/:id" element={<FaceSwapEdit />} />

                  <Route path="category-list" element={<CategoryList />} />
                  <Route path="create-category" element={<CategoryCreate />} />
                  <Route path="category-edit/:id" element={<CategoryEdit />} />
              </Route>
          </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
