import React, {useEffect} from 'react'
import {useState} from "react";
import {useNavigate} from 'react-router-dom'
import axios from "axios";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');  // State để lưu trữ lỗi
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://picfixer.app/api/auth/login', { email, password });
            const token = response.data?.access_token ?? '';
            if (!response.data?.success || !token) {
                setError(response.data?.message('Invalid username or password'));
            } else {
                localStorage.setItem('token', token);
                setError('');
                navigate('portal/fairy-ai-list');
            }
        } catch (error) {
            console.error('Đăng nhập thất bại:', error);
        }
    };

    useEffect(() => {

    }, [error])
    return (
        <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        {/* <!-- Nested Row within Card Body --> */}
                        <div className="row align-content-center">
                            <div className="col-lg-12">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                    </div>
                                    <form className="user" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control form-control-user"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                placeholder="Password"
                                                className="form-control form-control-user"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox small">
                                                <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                <label className="custom-control-label" for="customCheck">Remember
                                                    Me</label>
                                            </div>
                                        </div>
                                        <button type='submit' className="btn btn-primary btn-user btn-block">
                                            Login
                                        </button>
                                        {error && <div style={{ color: 'red' }}>{error}</div>}
                                        <hr />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Login