import axios from 'axios';
import {Field, useFormik} from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function Edit() {
    const params = useParams();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getFairyAiData().then(() => {})
    }, [])

    let getFairyAiData = async () => {
        try {
            const res = await axios.get(`https://picfixer.app/api/fairy-ai/detail/${params.id}`);
            const fairyAi = res.data?.data ?? []
            if (!fairyAi) {
                navigate("/portal/fairy-ai-list")
            } else {
                await myFormik.setValues(fairyAi);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            navigate("/portal/fairy-ai-list")
        }
    }

    const myFormik = useFormik({
        initialValues: {
            uuid: "",
            name: "",
            category: "",
            has_v2: 0,
            premium: 0,
            thumbnail_url: "",
            thumbnail: null
        },
        // Validating Forms while entering the data
        validate: (values) => {
            let errors = {}           //Validating the form once the error returns empty else onsubmit won't work

            if (!values.uuid) {
                errors.uuid = "Please enter uuid";
            }

            return errors;
        },

        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append("uuid", values.uuid);
                formData.append("name", values.name);
                formData.append("category", values.category);
                formData.append("has_v2", values.has_v2);
                formData.append("premium", values.premium);
                if (values.thumbnail) {
                    formData.append("thumbnail", values.thumbnail);
                }
                await axios.post(`https://picfixer.app/api/fairy-ai/update/${params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setLoading(false);
                navigate("/portal/fairy-ai-list")
            } catch (error) {
                setLoading(false);
            }
        }
    })
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            myFormik.setFieldValue('thumbnail', file);

            // Tạo URL cho file ảnh để hiển thị xem trước
            const reader = new FileReader();
            reader.onloadend = () => {
                myFormik.setFieldValue('thumbnail_url', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <>
            <h3>FairyAi - Id : {params.id} </h3>
            <div className='container'>
                <form onSubmit={myFormik.handleSubmit}>
                    <div className='row'>
                        <div className="col-lg-6">
                            <label className='font-weight-bold'>UUID</label>
                            <input name='uuid' value={myFormik.values.uuid} onChange={myFormik.handleChange} type={"text"}
                                className={`form-control ${myFormik.errors.uuid ? "is-invalid" : ""} `} />
                            <span style={{ color: "red" }}>{myFormik.errors.uuid}</span>
                        </div>

                        <div className="col-lg-6">
                            <label className='font-weight-bold'>Name</label>
                            <input name='name' value={myFormik.values.name} onChange={myFormik.handleChange} type={"mail"}
                                className={`form-control ${myFormik.errors.name ? "is-invalid" : ""} `} />
                            <span style={{ color: "red" }}>{myFormik.errors.name}</span>
                        </div>

                        <div className='col-lg-12'>
                            <label className='font-weight-bold'>Category</label>
                            <select name='category' value={myFormik.values.category} onChange={myFormik.handleChange}
                                    className={`form-control ${myFormik.errors.category ? "is-invalid" : ""} `} >
                                <option value="">----Select----</option>
                                <option value="cartoon">Cartoon</option>
                                <option value="anime">Anime</option>
                                <option value="restyle">Restyle</option>
                            </select>
                            <span style={{ color: "red" }}>{myFormik.errors.category}</span>
                        </div>

                        <div className='col-lg-4'>
                            <label className='font-weight-bold'>Premium</label>
                            <div className='col-lg-6 pl-4'>
                                <input
                                    type="radio"
                                    name="premium"
                                    id="premium-true"
                                    value={1}
                                    checked={myFormik.values.premium == 1}
                                    onChange={myFormik.handleChange}
                                    className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label" htmlFor="premium-true">True</label>
                            </div>
                            <div className='col-lg-6 pl-4'>
                                <input
                                    type="radio"
                                    name="premium"
                                    id="premium-false"
                                    value={0}
                                    checked={myFormik.values.premium == 0}
                                    onChange={myFormik.handleChange}
                                    className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label" htmlFor="premium-false">False</label>
                            </div>
                            <span style={{ color: "red" }}>{myFormik.errors.premium}</span>
                        </div>

                        <div className='col-lg-4'>
                            <label className='font-weight-bold'>Has V2</label>
                            <div className='col-lg-6 pl-4'>
                                <input
                                    type="radio"
                                    name="has_v2"
                                    id="has_v2-true"
                                    value={1}
                                    checked={myFormik.values.has_v2 == 1}
                                    onChange={myFormik.handleChange}
                                    className={`form-check-input ${myFormik.errors.has_v2 ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label" htmlFor="has_v2-true">True</label>
                            </div>
                            <div className='col-lg-6 pl-4'>
                                <input
                                    type="radio"
                                    name="has_v2"
                                    id="has_v2-false"
                                    value={0}
                                    checked={myFormik.values.has_v2 == 0}
                                    onChange={myFormik.handleChange}
                                    className={`form-check-input ${myFormik.errors.has_v2 ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label" htmlFor="has_v2-false">False</label>
                            </div>
                            <span style={{ color: "red" }}>{myFormik.errors.has_v2}</span>
                        </div>

                        <div className='col-lg-12'>
                            <label className='font-weight-bold'>Image</label>
                            { myFormik.values.thumbnail_url ? (
                                <>
                                    <img src={myFormik.values.thumbnail_url} alt="Thumbnail" className='fairy-ai-img' />
                                </>
                            ) : (
                                <>
                                    <p>Image empty</p>
                                </>
                            )}
                            <input type="file" name="thumbnail" accept="image/*" className='form-control-file' onChange={handleImageUpload}/>

                        </div>

                        <div className='col-lg-4 mt-3'>
                            <input disabled={isLoading} type="submit" value={isLoading ? "Updating..." : "Update"} className=' btn btn-primary' />
                        </div>
                    </div>
                </form>
                {/* {JSON.stringify(myFormik.values)} */}
            </div>
        </>


    )
}

export default Edit