import axios from 'axios';
import {Field, useFormik} from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function FaceDanceEdit() {
    const params = useParams();
    const [isLoading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getFaceDanceData().then(() => {})
        getCategoryList().then(() => {})
    }, [])

    let getCategoryList = async () => {
        try {
            const res = await axios.get("https://picfixer.app/api/categories/list", {
                params: {
                    type: 'face_dance'
                }
            });
            setCategories(res.data?.data?.items ?? []);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    let getFaceDanceData = async () => {
        try {
            const res = await axios.get(`https://picfixer.app/api/face-dance/detail/${params.id}`);
            const faceDance = res.data?.data ?? []
            if (!faceDance) {
                navigate("/portal/face-dance-list")
            } else {
                await myFormik.setValues(faceDance);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            navigate("/portal/face-dance-list")
        }
    }

    const myFormik = useFormik({
        initialValues: {
            name: "",
            category_id: 0,
            premium: 0,
            video: null,
            video_url: ""
        },
        // Validating Forms while entering the data
        validate: (values) => {
            let errors = {}

            return errors;
        },

        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append("name", values.name);
                formData.append("category_id", values.category_id);
                formData.append("premium", values.premium);

                if (values.video) {
                    formData.append("video", values.video);
                }
                await axios.post(`https://picfixer.app/api/face-dance/update/${params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setLoading(false);
                navigate("/portal/face-dance-list")
            } catch (error) {
                setLoading(false);
            }
        }
    })
    const isVideo = (url) => {
        return url && url.match(/\.(mp4|webm|ogg)$/i);
    };
    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            myFormik.setFieldValue('video', file);

            // Tạo URL cho file ảnh để hiển thị xem trước
            const reader = new FileReader();
            reader.onloadend = () => {
                myFormik.setFieldValue('video_url', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <h3>FaceDance - Id : {params.id} </h3>
            <div className='container'>
                <form onSubmit={myFormik.handleSubmit}>
                    <div className='row'>

                        <div className="col-lg-6">
                            <label className='font-weight-bold'>Name</label>
                            <input name='name' value={myFormik.values.name} onChange={myFormik.handleChange} type={"mail"}
                                className={`form-control ${myFormik.errors.name ? "is-invalid" : ""} `} />
                            <span style={{ color: "red" }}>{myFormik.errors.name}</span>
                        </div>

                        <div className='col-lg-12'>
                            <label className='font-weight-bold'>Category</label>
                            <select
                                name='category_id'
                                value={myFormik.values.category_id}
                                onChange={myFormik.handleChange}
                                className={`form-control ${myFormik.errors.category_id ? "is-invalid" : ""}`}
                            >
                                <option value="">----Select----</option>
                                {categories.map((category, index) => {
                                    let categoryName = category?.name ?? '';
                                    let typeLabel = categoryName.replace('_', ' ');
                                    let capitalizedLabel = typeLabel
                                        .split(' ')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ');
                                    return (
                                        <option key={index} value={category.id}>{capitalizedLabel}</option>
                                    )
                                })}
                            </select>
                            <span style={{ color: "red" }}>{myFormik.errors.category_id}</span>
                        </div>

                        <div className='col-lg-4'>
                            <label className='font-weight-bold'>Premium</label>
                            <div className='col-lg-6 pl-4'>
                                <input
                                    type="radio"
                                    name="premium"
                                    id="premium-true"
                                    value={1}
                                    checked={myFormik.values.premium == 1}
                                    onChange={myFormik.handleChange}
                                    className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label" htmlFor="premium-true">True</label>
                            </div>
                            <div className='col-lg-6 pl-4'>
                                <input
                                    type="radio"
                                    name="premium"
                                    id="premium-false"
                                    value={0}
                                    checked={myFormik.values.premium == 0}
                                    onChange={myFormik.handleChange}
                                    className={`form-check-input ${myFormik.errors.premium ? "is-invalid" : ""}`}
                                />
                                <label className="form-check-label" htmlFor="premium-false">False</label>
                            </div>
                            <span style={{ color: "red" }}>{myFormik.errors.premium}</span>
                        </div>

                        <div className='col-lg-12'>
                            <label className='font-weight-bold'>Video</label>
                            {myFormik.values.video_url ? (
                                <>
                                    {isVideo(myFormik.values.video_url) ? (
                                        <video controls className='face-dance-video'>
                                            <source src={myFormik.values.video_url} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : ''}
                                </>
                            ) : (
                                <p>Video empty</p>
                            )}
                            <input
                                type="file"
                                name="video"
                                accept="video/*"
                                className='form-control-file'
                                onChange={handleVideoUpload}
                            />
                        </div>



                        <div className='col-lg-4 mt-3'>
                            <input disabled={isLoading} type="submit" value={isLoading ? "Updating..." : "Update"} className=' btn btn-primary' />
                        </div>
                    </div>
                </form>
                {/* {JSON.stringify(myFormik.values)} */}
            </div>
        </>


    )
}

export default FaceDanceEdit